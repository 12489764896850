import React from "react"
import { Link } from "gatsby"
import headerStyles from "../styles/components/header.module.scss"
import MenuAppBar from '../components/MenuAppBar'
import moreGoodLogo from "../assets/images/more-good-logo.png";

export default function Header(props) {
  return (
    <header>
      <nav
        className={headerStyles.header__nav}
        role="navigation"
        aria-label="main navigation"
      >
        <Link to="/">
            <img src={moreGoodLogo} alt="" />
        </Link>
        {/* <div>
          <p className={headerStyles.account__nav}><Link to="/account">My Account</Link></p>
        </div> */}
      </nav>
    </header>
  )
}